import React, { createContext, useCallback, useContext, useLayoutEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { match, matchPath, useLocation } from 'react-router-dom';

export const breadCrumbsData = {
  '/people': {
    text: 'navigation_team.team',
    to: '/people/org-structure/all-employees',
    sub: {
      '/org-structure': {
        text: 'navigation_team.structure',
        to: '/people/org-structure/all-employees',
        sub: {
          '/all-employees': {
            text: 'structure.all_employees_tab',
            to: '/people/org-structure/all-employees',
          },
          '/current-structure': {
            text: 'structure.current_structure_tab',
            to: '/people/org-structure/current-structure',
          },
          '/created-structures': {
            text: 'structure.created_structures_tab',
            to: '/people/org-structure/created-structures',
          },
          '/view': {
            back: true,
          },
        },
      },
      '/holding-structure': {
        text: 'navigation_team.holding_structure',
        to: '/people/holding-structure',
      },
      '/timesheets': {
        text: 'navigation_team.timesheets',
        to: '/people/timesheets?tab=myTimesheet',
        sub: {
          '/myTimesheet': {
            text: 'timesheets.my_timesheet_tab',
            to: '/people/timesheets?tab=myTimesheet',
          },
          '/pending': {
            text: 'timesheets.for_approval_tab',
            to: '/people/timesheets?tab=pending',
          },
          '/myTeam': {
            text: 'timesheets.my_team_tab',
            to: '/people/timesheets?tab=myTeam',
          },
          '/allTimesheets': {
            text: 'timesheets.all_timesheets_tab',
            to: '/people/timesheets?tab=allTimesheets',
          },
        },
      },
      '/profile/:id/profile': {
        text: 'navigation_team.profile',
        to: '/people/profile/:id/profile',
        sub: {
          '/basic-info': {
            text: 'navigation_team.basic_info',
            to: '/people/profile/:id/profile/',
          },
          '/personal': {
            text: 'navigation_team.personal_info',
            to: `/people/profile/:id/profile/personal`,
          },
          '/work': {
            text: 'navigation_team.work',
            to: '/people/profile/:id/profile/work',
          },
          '/contract': {
            text: 'navigation_team.contract',
            to: '/people/profile/:id/profile/contract',
          },
          '/workload': {
            text: 'navigation_team.workload',
            to: '/people/profile/:id/profile/workload',
          },
          '/time-off': {
            text: 'Time off',
            to: '/people/profile/:id/profile/time-off',
          },
        },
      },
      '/settings': {
        text: 'permissions.role_tab_col_perm',
        to: '/people/settings',
        sub: {
          '/roles': {
            text: 'permissions.roles_tab',
            to: '/people/settings?tab=roles',
          },
          '/users&page=0&size=10': {
            text: 'permissions.users_tab',
            to: '/people/settings?tab=users',
          },
          '/confidential_data': {
            text: 'permissions.conf_data_group',
            to: '/people/settings?tab=confidential_data',
          },
        },
      },
    },
  },
};

type BreadCrumbType = {
  to: string;
  text: string;
  back?: boolean;
  sub?: Record<string, BreadCrumbType>;
};
//TODO: Update ts version to 4.1.x
type DeepKeys<T, BL = ''> = T extends object
  ? {
      [K in keyof T]-?: K extends string | number
        ? K extends BL
          ? never
          : K extends 'sub'
          ? DeepKeys<T[K], BL>
          : string
        : // : `${K}` | `${K}${DeepKeys<T[K], BL>}`
          never;
    }[keyof T]
  : never;

type AllRoutesKeys = DeepKeys<typeof breadCrumbsData, 'to' | 'text'>;

export const allBreadCrumbRoutes = (() => {
  const parse = (ob: BreadCrumbType['sub'], parentPath = '') => {
    if (!ob) return;
    return Object.entries(ob).reduce((acc, [k, v]) => {
      acc[parentPath + k] = v;
      acc = { ...acc, ...parse(v.sub, parentPath + k) };
      return acc;
    }, {} as Record<AllRoutesKeys, BreadCrumbType>);
  };
  return parse((breadCrumbsData as unknown) as BreadCrumbType['sub']);
})() as Required<BreadCrumbType>['sub'];

type BreadcrumbContextType = {
  breadcrumbs: BreadCrumbType[];
  addBreadcrumb: (breadcrumb: BreadCrumbType) => void;
  removeBreadcrumb: () => void;
  changePositionBreadcrumb: (positionIndex: number, breadcrumb: BreadCrumbType) => void;
};

export const BreadcrumbContext = createContext<BreadcrumbContextType | undefined>(undefined);

export const BreadcrumbProvider: React.FC = ({ children }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const matches = Object.keys(allBreadCrumbRoutes)
    .map((value) => matchPath(location.pathname, value))
    .filter(Boolean) as match[];

  const matchData = matches.map((v) => ({
    ...allBreadCrumbRoutes[v.path],
    text: t(allBreadCrumbRoutes[v.path].text),
    to: v.url,
  }));
  const formatBreadCrumbs = useCallback(
    (bc: BreadCrumbType[]) => {
      return bc.map((v) => ({ ...v, text: t(v?.text) })).sort((a, b) => (a.back ? -1 : 1));
    },
    [t]
  );
  const [breadcrumbs, setBreadcrumbs] = useState<BreadCrumbType[]>(formatBreadCrumbs(matchData));
  useLayoutEffect(() => {
    setBreadcrumbs(matchData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const addBreadcrumb = useCallback(
    (breadcrumb: BreadCrumbType) => {
      setBreadcrumbs((prevState) => {
        // Check if breadcrumb already exists
        const exists = prevState.some((prevBreadcrumb) => prevBreadcrumb.to === breadcrumb.to);

        // If breadcrumb doesn't exist, add it to state
        if (!exists) {
          return formatBreadCrumbs([...prevState, breadcrumb]);
        }

        // If breadcrumb already exists, return previous state
        return formatBreadCrumbs(prevState);
      });
    },
    [formatBreadCrumbs]
  );

  const removeBreadcrumb = useCallback(() => {
    setBreadcrumbs((prevState) => formatBreadCrumbs(prevState.slice(0, -1)));
  }, [formatBreadCrumbs]);

  const changePositionBreadcrumb = useCallback(
    (positionIndex: number, breadcrumb: BreadCrumbType) => {
      setBreadcrumbs((prevState) => {
        const newState = [...prevState];
        newState[positionIndex] = breadcrumb;
        return formatBreadCrumbs(newState);
      });
    },
    [formatBreadCrumbs]
  );
  const cmd = useMemo(() => {
    return { breadcrumbs, addBreadcrumb, removeBreadcrumb, changePositionBreadcrumb };
  }, [addBreadcrumb, breadcrumbs, changePositionBreadcrumb, removeBreadcrumb]);
  return <BreadcrumbContext.Provider value={cmd}>{children}</BreadcrumbContext.Provider>;
};

export const useBreadCrumbs = () => useContext(BreadcrumbContext);
