/* eslint-disable react-hooks/rules-of-hooks */
import { PaginatedResponse, Timesheet } from '@dms-hcms-web/api-interfaces';

import { environment } from '@environment';

import { useWorkspace } from './../hooks/useWorkspace';
import httpClient from './http';

export const getMonths = (params?: Omit<Timesheet.GetMonths.QueryParams, 'holdingId'>) => {
  const [workspace] = useWorkspace();
  return httpClient.get<Timesheet.GetMonths.ResponseDto>(`${environment.baseApiUrl}/timesheets/months`, {
    params: {
      holdingId: workspace.holdingId,
      ...params,
    },
  });
};

export const getMonthById = ({ timesheetId, companyId, employeeId }: Timesheet.GetMonth.QueryParams) => {
  return httpClient.get<Timesheet.GetMonth.ResponseDto>(`${environment.baseApiUrl}/timesheets/month/${timesheetId}`, {
    params: {
      companyId,
      employeeId,
    },
  });
};

export const editTimesheet = ({ timesheetId, body, companyId, employeeId }: Timesheet.EditTimesheet.RequestDto) => {
  const [company] = useWorkspace();
  return httpClient.put<Timesheet.EditTimesheet.ResponseDto>(
    `${environment.baseApiUrl}/timesheets/month/${timesheetId}`,
    body,
    {
      params: {
        companyId: companyId || company.id,
        employeeId,
      },
    }
  );
};

export const setTimesheetStatus = ({ timesheetId, body, companyId }: Timesheet.SetTimesheetStatus.RequestDto) => {
  const [company] = useWorkspace();
  return httpClient.post<Timesheet.SetTimesheetStatus.ResponseDto>(
    `${environment.baseApiUrl}/timesheets/month/${timesheetId}/status`,
    body,
    {
      params: {
        companyId: companyId || company.id,
      },
    }
  );
};

export const getComments = ({ timesheetId, companyId, employeeId }: Timesheet.GetComments.QueryParams) => {
  return httpClient.get<Timesheet.GetComments.ResponseDto>(
    `${environment.baseApiUrl}/timesheets/comments/${timesheetId}`,
    {
      params: {
        companyId,
        employeeId,
      },
    }
  );
};

export const addComment = ({ timesheetId, body, companyId, employeeId }: Timesheet.AddComment.RequestDto) => {
  const [company] = useWorkspace();
  return httpClient.post<Timesheet.AddComment.ResponseDto>(
    `${environment.baseApiUrl}/timesheets/comments/${timesheetId}`,
    body,
    {
      params: {
        employeeId,
        companyId: companyId || company.id,
      },
    }
  );
};

export const getManagerTimesheets = (params?: Omit<Timesheet.GetManagerTimesheets.QueryParams, 'holdingId'>) => {
  const [company] = useWorkspace();
  return httpClient.get<PaginatedResponse<Timesheet.GetManagerTimesheets.ResponseDto>>(
    `${environment.dmsBaseApi}/hcms/main-api/timesheets/supervisor`,
    {
      params: {
        holdingId: company.holdingId,
        ...params,
      },
    }
  );
};

export const getHRMonthTerminatedTimesheets = (
  params: Omit<Timesheet.GetHRMonthTerminated.QueryParams, 'companyId'>
) => {
  const [company] = useWorkspace();
  return httpClient.get<Timesheet.GetHRMonthTerminated.ResponseDto>(
    `${environment.baseApiUrl}/timesheets/hr/terminated`,
    {
      params: { ...params, companyId: company.id },
    }
  );
};

export const getHRHeadings = () => {
  const [company] = useWorkspace();
  return httpClient.get<Timesheet.GetHRHeadings.ResponseDto>(`${environment.baseApiUrl}/timesheets/hr/headings`, {
    params: { holdingId: company.holdingId },
  });
};

export const getStaffList = (params: Timesheet.GetHRStaffList.QueryParams) => {
  return httpClient.get<Timesheet.GetHRStaffList.ResponseDto>(
    `${environment.dmsBaseApi}/hcms/main-api/hr/timesheets/stafflist`,
    {
      params,
    }
  );
};

export const getHeader = (params: Timesheet.GetHRHeader.QueryParams) => {
  return httpClient.get<Timesheet.GetHRHeader.ResponseDto>(`${environment.baseApiUrl}/timesheets/hr/header`, {
    params,
  });
};

export const hrApproveAllTimesheets = (params: Timesheet.ApproveHRAll.QueryParams) => {
  return httpClient.post<Timesheet.ApproveHRAll.ResponseDto>(`${environment.baseApiUrl}/timesheets/hr/approve`, null, {
    params,
  });
};

export const hrApproveTerminatedTimesheets = ({
  year,
  month,
  terminationDate,
}: Omit<Timesheet.ApproveHRTerminated.QueryParams, 'companyId'>) => {
  const [company] = useWorkspace();
  return httpClient.post<Timesheet.ApproveHRTerminated.ResponseDto>(
    `${environment.baseApiUrl}/timesheets/hr/approve/terminated`,
    null,
    {
      params: {
        year,
        month,
        terminationDate,
        companyId: company.id,
      },
    }
  );
};

export const checkTimesheetsForApprove = ({
  year,
  month,
  companyId,
}: Timesheet.CheckTimesheetsForApprove.QueryParams) => {
  return httpClient.get<Timesheet.CheckTimesheetsForApprove.ResponseDto>(
    `${environment.baseApiUrl}/timesheets/hr/analyze`,
    {
      params: { year, month, companyId },
    }
  );
};

export const checkTerminatedTimesheetsForApprove = ({
  year,
  month,
  terminationDate,
}: Omit<Timesheet.CheckTerminatedTimesheetsForApprove.QueryParams, 'companyId'>) => {
  const [company] = useWorkspace();
  return httpClient.get<Timesheet.CheckTerminatedTimesheetsForApprove.ResponseDto>(
    `${environment.baseApiUrl}/timesheets/hr/terminated/analyze`,
    {
      params: { companyId: company.id, year, month, terminationDate },
    }
  );
};

export const exportTimesheet = (data: Timesheet.ExportTimesheet.RequestDto) => {
  const [company] = useWorkspace();
  return httpClient.post<Timesheet.ExportTimesheet.ResponseDto>(
    `${environment.baseApiUrl}/timesheets/hr/export`,
    data,
    { params: { companyId: company.id } }
  );
};

export const timesheetExportStatus = ({ id }: Timesheet.TimesheetExportStatus.QueryParams) => {
  const [company] = useWorkspace();
  return httpClient.get<Timesheet.TimesheetExportStatus.ResponseDto>(
    `${environment.baseApiUrl}/timesheets/hr/export/status/${id}`,
    {
      params: { companyId: company.id },
    }
  );
};

export const generateTimesheets = (params: Timesheet.GenerateTimesheets.QueryParams) => {
  return httpClient.post<Timesheet.GenerateTimesheets.ResponseDto>(
    `${environment.baseApiUrl}/timesheets/hr/generate`,
    null,
    {
      params: params,
    }
  );
};

export const verifyTimesheetsWithOneC = ({
  year,
  month,
}: Omit<Timesheet.VerifyTimesheetsWithOneC.QueryParams, 'companyId'>) => {
  const [company] = useWorkspace();
  return httpClient.get<Timesheet.VerifyTimesheetsWithOneC.ResponseDto>(
    `${environment.baseApiUrl}/timesheets/hr/verify`,
    {
      params: { companyId: company.id, year, month },
    }
  );
};

export const verifyTerminatedTimesheetsWithOneC = ({
  year,
  month,
  terminationDate,
}: Timesheet.VerifyTimesheetsWithOneC.QueryParams) => {
  const [company] = useWorkspace();
  return httpClient.get<Timesheet.VerifyTerminatedTimesheetsWithOneC.ResponseDto>(
    `${environment.baseApiUrl}/timesheets/hr/terminated/verify`,
    {
      params: { companyId: company.id, year, month, terminationDate },
    }
  );
};

export const getTimesheetsWithErrors = ({ year, month }: Timesheet.GetTimesheetsWithErrors.QueryParams) => {
  const [company] = useWorkspace();
  return httpClient.get<Timesheet.GetTimesheetsWithErrors.ResponseDto>(
    `${environment.baseApiUrl}/timesheets/hr/errors`,
    {
      params: { companyId: company.id, year, month },
    }
  );
};

export const getTerminatedTimesheetsWithErrors = ({
  year,
  month,
  terminationDate,
}: Timesheet.GetTerminatedTimesheetsWithErrors.QueryParams) => {
  const [company] = useWorkspace();
  return httpClient.get<Timesheet.GetTerminatedTimesheetsWithErrors.ResponseDto>(
    `${environment.baseApiUrl}/timesheets/hr/terminated/errors`,
    {
      params: { companyId: company.id, year, month, terminationDate },
    }
  );
};

export const getTimesheetsBadges = () => {
  const [company] = useWorkspace();
  return httpClient
    .get<Timesheet.GetTimesheetsBadges.ResponseDto>(`${environment.baseApiUrl}/timesheets/badges`, {
      params: {
        holdingId: company.holdingId,
      },
    })
    .then((res) => res.data);
};

export const approveTimesheetsByIdsOrAll = (body: Timesheet.ApproveTimesheetsByIdsOrAll.RequestDto) => {
  return httpClient
    .post<Timesheet.ApproveTimesheetsByIdsOrAll.ResponseDto>(
      `${environment.dmsBaseApi}/hcms/main-api/timesheets/list/approve`,
      body
    )
    .then((res) => res.data);
};

export const approveMonthTimesheetsByIdsOrAll = ({
  companyId,
  year,
  month,
  includeAll,
  includeIds,
  excludeIds,
  status,
}: Timesheet.ApproveMonthTimesheetsByIdsOrAll.RequestDto) => {
  return httpClient
    .post<Timesheet.ApproveMonthTimesheetsByIdsOrAll.ResponseDto>(
      `${environment.dmsBaseApi}/hcms/main-api/hr/timesheets/list/approve?companyId=${companyId}&year=${year}&month=${month}&status=${status}`,
      { includeAll, includeIds, excludeIds }
    )
    .then((res) => res.data);
};

export const getTimesheetCounters = async ({
  includeAll,
  includeIds,
  excludeIds,
}: Timesheet.GetTimesheetCounters.RequestDto) => {
  return httpClient
    .put<Timesheet.GetTimesheetCounters.ResponseDto>(
      `${environment.dmsBaseApi}/hcms/main-api/timesheets/count?statuses=VERIFYING_BY_SUPERVISOR`,
      { includeAll, includeIds, excludeIds }
    )
    .then((res) => res.data);
};

export const getMonthTimesheetCounters = async ({
  companyId,
  year,
  month,
  includeAll,
  includeIds,
  excludeIds,
}: Timesheet.GetMonthTimesheetCounters.RequestDto) => {
  return httpClient
    .put<Timesheet.GetMonthTimesheetCounters.ResponseDto>(
      `${environment.dmsBaseApi}/hcms/main-api/hr/timesheets/count?companyId=${companyId}&year=${year}&month=${month}&statuses=VERIFYING_BY_SUPERVISOR,VERIFYING_BY_HR`,
      { includeAll, includeIds, excludeIds }
    )
    .then((res) => res.data);
};

export const exportTimesheetExcel = ({ workspaceId, year, month }: Timesheet.GetTimesheetExcel.RequestDto) => {
  return httpClient
    .post(`${environment.dmsBaseApi}/hcms/main-api/hr/timesheets/workspace/${workspaceId}/export`, null, {
      params: { year, month },
      headers: { 'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
      responseType: 'blob',
    })
    .then((res) => res.data);
};

export const getSavedTimesheetsCount = ({
  year,
  month,
  workspaceId,
}: Timesheet.GetSavedTimesheetsCount.QueryParams) => {
  return httpClient
    .get<Timesheet.GetSavedTimesheetsCount.ResponseDto>(
      `${environment.dmsBaseApi}/hcms/main-api/hr/timesheets/saved/count`,
      {
        params: { year, month, workspaceId },
      }
    )
    .then((res) => res.data);
};
