import { BreadCrumbs, Header } from '@dar-dms/utils';
import React from 'react';

import { BackButton } from '@components/back-button/back-button';

import { useBreadCrumbs } from '@utils/breadcrumbs';

const HeaderWrapper = () => {
  const { breadcrumbs } = useBreadCrumbs();
  const hasBack = Boolean(breadcrumbs.find((bc) => bc.back));
  const filteredBreadCrumbs = (hasBack ? breadcrumbs.slice(1) : breadcrumbs) as BreadCrumbs[];

  return <Header breadcrumbs={filteredBreadCrumbs} breadcrumbsBefore={hasBack && <BackButton text="" />} />;
};

export default HeaderWrapper;
