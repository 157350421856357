import { AuthService, AuthStatus } from '@dartech/dms-auth';
import { toast } from '@dartech/dms-ui';
import axios, { AxiosResponse } from 'axios';
import i18next from 'i18next';

import { AxiosResponseError } from '@dms-hcms-web/api-interfaces';

import { environment } from '@environment';

const authService = AuthService.createInstance({
  griffonApiRoot: environment.griffonApiRoot,
  griffonClientId: environment.griffonClientId,
  griffonRedirectUrl: environment.griffonRedirectUrl,
  bffRoot: environment.authBffRoot,
});

const httpClient = axios.create();

httpClient.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (axiosError: AxiosResponseError) => {
    if (axiosError.response?.status === 401) {
      try {
        await authService.login();
      } catch (error) {
        authService.loginStatus.emit(AuthStatus.UNAUTHORIZED, true);
      }
    }
    const translatedErrorMessage = i18next.t(axiosError.developerMessage, {
      ns: 'exceptions',
    });
    if (translatedErrorMessage !== axiosError.developerMessage) {
      toast.error(translatedErrorMessage);
    }
    return Promise.reject(axiosError.response?.data);
  }
);

httpClient.interceptors.request.use((config) => {
  const auth = JSON.parse(localStorage.getItem('dms-auth'));
  config.headers['Authorization'] = `Bearer ${auth?.id_token}`;

  config.headers['accept-language'] = localStorage.getItem('i18nextLng');
  if (!config.headers['Workspace-Authorization']) {
    const workspaceAuth = localStorage.getItem('workspace_token');
    if (workspaceAuth?.length) {
      config.headers['Workspace-Authorization'] = workspaceAuth;
    }
    config.headers['dar-dms-user-id'] = JSON.parse(localStorage.getItem('profile'))?.id;
    config.headers['dar-dms-org-id'] = JSON.parse(localStorage.getItem('workspace')).id;
  }

  return config;
});

export default httpClient;
